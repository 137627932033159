












































import Vue from "vue";
import Pricer from "~/src/components/Pricer.vue";
import model_en from "./model_en.json";
import model_fr from "./model_fr.json";
import Hero from "./components/Hero.vue";
import EmailForm from "./components/EmailForm.vue";
import SupportItem from "./components/SupportItem.vue";
import TeamSizeItem from "./components/TeamSizeItem.vue";

export default Vue.extend({
	data() {
		return {
			pricing: {
				population: null,
				offer: null,
			},
			sendByEmail: true,
			model:
				navigator && navigator.language && navigator.language === "fr-FR"
					? model_fr
					: model_en,
		};
	},
	computed: {
		price() {
			if (!this.pricing.population || !this.pricing.offer) return null;
			return this.model.ranges.find(
				(range) => this.pricing.population < range.population
			);
		},
	},
	components: { Hero, TeamSizeItem, SupportItem, Pricer, EmailForm },
});
