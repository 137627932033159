
















import Vue from "vue";

export default Vue.extend({
	props: ["price", "offer", "population", "wordings"],
	computed: {
		slice() {
			return this.offer === "autonomous"
				? 0
				: this.offer === "tranquility"
				? 1
				: 2;
		},
		totalPrice() {
			return this.price.price.slices[this.slice] + this.price.price.solution;
		},
	},
});
