






























import checkIcons from "../assets/check-icons/*.svg";

export default {
	data: () => ({
		checkIcons,
	}),
	props: ["id", "theme", "name", "list", "price", "selected", "index", "wordings",
};
